<template>
  <section class="flex flex-row-reverse flex-wrap map-height">
    <div class="w-full md:h-full md:w-3/4 gurgelmepps" ref="goggers"></div>
    <div class="w-full h-full md:w-1/4 font-gill md:overflow-y-scroll">
      <div class="flex w-full pr-2">
        <div class="mt-4 mr-2 md:mt-0">
          <input
            ref="searchBar"
            class="w-full text-center border-2 border-black rounded"
            type="search"
            placeholder="Enter a zip code"
            v-model="postcodeInput"
          />
          <p
            class="text-xs underline cursor-pointer"
            @click="clearSearch"
            v-if="postcodeInput"
          >
            Clear
          </p>
        </div>
        <label for="search-radius" class="text-xs">
          <select
            id="search-radius"
            v-model="searchRadius"
            @change="handleSearchRadius"
            class="block mt-4 mr-2 text-base text-center border-2 border-black rounded md:mt-0"
          >
            <option value="5">5 miles</option>
            <option value="10">10 miles</option>
            <option value="15">15 miles</option>
            <option value="20">20 miles</option>
          </select>
          Search radius
        </label>

        <!-- <button class="w-1/3 px-2 text-white rounded bg-blue" @click="handleSearch">search</button> -->
      </div>

      <transition-group
        tag="ul"
        name="slide"
        class="flex flex-wrap pr-2 mt-4 text-sm leading-tight md:block"
      >
        <li
          v-for="(place, i) in nearbyLocations"
          :key="place.name + i"
          @click="handlePlaceClick(place.name)"
          class="w-1/2 py-4 cursor-pointer md:py-0 md:w-full md:mb-6 hover:text-blue slide-item"
        >
          <h3 class="font-semibold">
            {{ place.name }}
          </h3>
          <p class="font-medium" v-if="place.place.parts.address">
            {{ place.place.parts.address }}, {{ place.place.parts.postcode }}
          </p>
          <p class="">
            {{ place.category }}
          </p>
        </li>
      </transition-group>
    </div>
  </section>
</template>

<script>
export default {
  name: 'google-map',
  props: ['locations', 'mapCenter'],

  data() {
    return {
      map: null,
      autocomplete: null,
      places: null,
      markers: [],
      currentMapCenter: null,
      postcodeInput: '',
      currentInfoWindow: null,
      searchRadius: 5,
      nearbyLocations: [],
    }
  },
  methods: {
    search() {
      this.nearbyLocations = []
      this.locations.forEach((loc) => {
        console.log(this.currentMapCenter.lat)
        if (
          getDistanceFromLatLonInMiles(
            this.currentMapCenter.lat,
            this.currentMapCenter.lng,
            loc.place.lat,
            loc.place.lng,
          ) < this.searchRadius
        ) {
          this.nearbyLocations.push(loc)
        }
      })
      this.clearMarkers()
      this.setupMarkers(this.map)
    },

    clearSearch() {
      this.postcodeInput = ''
      this.reset()
    },
    handleSearchRadius() {
      if (this.postcodeInput) {
        this.setMapCenter(this.currentMapCenter.lat, this.currentMapCenter.lng)
        this.search()
      }
    },
    handlePlaceClick(name) {
      const thePlace = this.markers.filter((markers) => {
        return markers.location.name === name
      })

      const marker = thePlace[0]
      this.setMapCenter(marker.location.place.lat, marker.location.place.lng)
      if (this.currentInfoWindow) {
        this.currentInfoWindow.close()
      }
      this.currentInfoWindow = marker.infoWindow
      this.currentInfoWindow.open(this.map, marker.marker)
    },
    handlePlaceChanged() {
      const place = this.autocomplete.getPlace()

      if (place.geometry) {
        this.setMapCenter(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
        )

        this.search()

        this.postcodeInput = place.formatted_address
      } else {
        console.log('woot')
        this.$refs.searchBar.placeholder = 'Enter a zip code'
      }
    },
    setMapCenter(lat, lng, zoom) {
      zoom = zoom || 14 - this.searchRadius / 5
      this.currentMapCenter = { lat, lng }
      this.map.setZoom(zoom)
      this.map.panTo(this.currentMapCenter)
    },
    clearMarkers() {
      for (const place of this.markers) {
        place.marker.setMap(null)
      }
      this.markers = []
    },
    setupMarkers(map) {
      const shape = {
        coords: [1, 1, 1, 20, 18, 20, 18, 1],
        type: 'poly',
      }

      for (let location of this.nearbyLocations) {
        const place = {}
        place.marker = new google.maps.Marker({
          position: { lat: location.place.lat, lng: location.place.lng },
          map,
          shape,
          title: location.name,
        })
        place.infoWindow = new google.maps.InfoWindow({
          content: `<div id="content" class="font-gill">
                <h1 class="mb-2 font-bold">${location.name}</h1>
                <p class="mb-2">${location.place.address}</p>
                <p><a target="_blank" rel="noreferrer nofollow" class="underline text-sd-blue" href="https://www.google.com/maps/dir/?api=1&destination=${location.place.lat},${location.place.lng}">Open in Google Maps</a></p>
            </div>`,
          maxWidth: 300,
        })

        place.marker.addListener('click', () => {
          place.infoWindow.open(this.map, place.marker)
        })

        place.location = location

        this.markers.push(place)
      }
    },
    reset() {
      this.setMapCenter(
        this.mapCenter.lat,
        this.mapCenter.lng,
        this.mapCenter.zoom,
      )
      this.nearbyLocations = this.locations
      this.setupMarkers(this.map)
    },
    initGoggers() {
      this.map = new google.maps.Map(this.$refs.goggers, {
        zoom: this.mapCenter.zoom,
        center: { lat: this.mapCenter.lat, lng: this.mapCenter.lng },
      })
      this.reset()

      // Create the autocomplete object and associate it with the UI input control.
      // Restrict the search to the default country, and to place type "cities".
      this.autocomplete = new google.maps.places.Autocomplete(
        /** @type {!HTMLInputElement} */ (this.$refs.searchBar),
        {
          types: ['(regions)'],
          componentRestrictions: { country: 'us' },
        },
      )
      this.autocomplete.addListener('place_changed', this.handlePlaceChanged)
      this.autocomplete.addListener('set_at', (e) => {
        console.log('WOOOT')
      })

      selectFirstOnEnter(this.$refs.searchBar)
    },
  },

  created() {
    if (!loadjs.isDefined('goggers')) {
      loadjs(
        [
          'https://maps.googleapis.com/maps/api/js?key=AIzaSyAKLLf_XjIeUlrGskpFCPProKmk-EtyXDE&libraries=places',
          'https://code.jquery.com/jquery-3.5.1.min.js',
        ],
        'goggers',
      )
    }
    const vm = this
    loadjs.ready('goggers', {
      success() {
        vm.initGoggers()
      },
    })
  },
}

function removeDuplicates(array) {
  return array.filter((a, b) => array.indexOf(a) === b)
}

function getDistanceFromLatLonInMiles(lat1, lon1, lat2, lon2) {
  var R = 3950 // Radius of the earth in miles
  var dLat = deg2rad(lat2 - lat1) // deg2rad below
  var dLon = deg2rad(lon2 - lon1)
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2)
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
  var d = R * c // Distance in km

  return d
}

function deg2rad(deg) {
  return deg * (Math.PI / 180)
}

function selectFirstOnEnter(input) {
  // store the original event binding function
  var _addEventListener = input.addEventListener
    ? input.addEventListener
    : input.attachEvent
  function addEventListenerWrapper(type, listener) {
    // Simulate a 'down arrow' keypress on hitting 'return' when no pac suggestion is selected, and then trigger the original listener.
    if (type == 'keydown') {
      var orig_listener = listener
      listener = function (event) {
        var suggestion_selected = $('.pac-item-selected').length > 0
        if (event.which == 13 && !suggestion_selected) {
          var simulated_downarrow = $.Event('keydown', {
            keyCode: 40,
            which: 40,
          })
          orig_listener.apply(input, [simulated_downarrow])
        }
        orig_listener.apply(input, [event])
      }
    }
    _addEventListener.apply(input, [type, listener]) // add the modified listener
  }
  if (input.addEventListener) {
    input.addEventListener = addEventListenerWrapper
  } else if (input.attachEvent) {
    input.attachEvent = addEventListenerWrapper
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 767px) {
  .gurgelmepps {
    height: 100vw;
  }
}
@media (min-width: 768px) {
  .map-height {
    height: 30rem;
  }
}

.slide-enter, .slide-leave-to
/* .slide-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(0.5rem);
}
.slide-leave-active {
  position: absolute;
}
.slide-item {
  transition: all 0.25s ease;
}
</style>
