import "./pcss/tailwind.pcss";
import "./scss/interaction.scss";
import "./scss/app.scss";
import lazySizes from "lazysizes";
import Vue from "vue";
import Components from "./components";
import store from "./store";
import axios from "axios";

// Root components

// Vue.mixin(inViewport)

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data() {
    return {
      mobNavOpen: false,
      ageConfirmed: null,
      showMcModal: false,
    };
  },
  methods: {
    toggleMobNav() {
      this.mobNavOpen = !this.mobNavOpen;
    },
    hideMcModal() {
      this.showMcModal = false;
      this.setCookie("hide-mc-modal", true, 365);
    },
    initMcModal() {
      if (!this.getCookie("hide-mc-modal") && this.getCookie("age-confirmed")) {
        this.showMcModal = true;
      }
    },
    setCookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },
    getCookie(cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
  },
  store,
  mounted() {
    setTimeout(() => {
      this.initMcModal();
    }, 1000);
  },
});
