<template>

  <button class="z-10">
    <div class="inner relative w-full h-full">
      <div class="line top"></div>
      <div class="line middle"></div>
      <div class="line bottom"></div>
    </div>

  </button>
</template>

<script>
export default {
  name: "burger",

  data() {
    return {};
  },
  created() {}
};
</script>

<style lang="scss" scoped>
button {
  position: absolute;
  width: 40px;
  height: 38px;
  top: 8px;
  left: 0.5rem;
  padding: 0.5rem 0.5rem;
  transition: transform 0.3s ease-out;

  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.line {
  position: absolute;
  width: 100%;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  // transform:scaleX(0.6);
  transform-origin: center;
  border-top: 2px solid white;

  &.top {
    top: 4px;
  }
  &.middle {
    top: 11px;
  }
  &.bottom {
    top: 18px;
  }
  transition: transform 0.3s ease;
}

.mob-nav-open {
  button {
    transform: translateX(256px);
  }
  .line {
    transform: rotate(45deg);
    &.top {
      transform: rotate(45deg);
      display: none;
    }
    &.bottom {
      transform: rotate(-45deg) translate(5px, -5px);
    }
  }
}
</style>