<template>
  <section class="opacity-0 gin-wrapper">
    <slot></slot>
  </section>
</template>

<script>
import TweenMax from "gsap";
import inViewport from "vue-in-viewport-mixin";

export default {
  name: "gin",
  mixins: [inViewport],
  data() {
    return {};
  },
  watch: {
    "inViewport.now": function(visible) {
      if (visible) {
        TweenMax.set(".gin-wrapper", { opacity: 1 });
        TweenMax.set(".gin-bottle", { opacity: 0 });
        TweenMax.fromTo(
          ".gin-bottle",
          1,
          { delay: 0.5, y: 100, opacity: 0 },
          { y: 0, opacity: 1 }
        );
        TweenMax.staggerFromTo(
          ".ingredient",
          1,
          { y: 800, opacity: 0 },
          { y: 0, opacity: 1, ease: Expo.easeOut },
          0.03
        );
        TweenMax.staggerFrom(
          ".gin-wrapper .graphic",
          1,
          { delay: 0.4, scale: 0.7, ease: Quint.easeOut },
          0.03
        );
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
</style>