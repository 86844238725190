<template>
    <div @mouseenter="enter" @mouseleave="leave">
      <dot-line ref="top" pos="top"></dot-line>
       <slot></slot> 
      <dot-line ref="bottom" pos="bottom"></dot-line>
  </div>
</template>

<script>
export default {
  name: "stray-link",
  props: ["active"],
  data() {
    return {
      animate: true
    };
  },
  methods: {
    enter() {
      if (this.animate) {
        // this.$refs.top.dotsIn.play(0);
        // this.$refs.bottom.dotsIn.play(0);
      }
    },
    leave() {
      if (this.animate) {
        // this.$refs.top.dotsOut.play(0);
        // this.$refs.bottom.dotsOut.play(0);
      }
    }
  },
  mounted() {
    // if (this.active === "	active") {
    //   this.enter();
    //   console.log("active");
    //   this.$refs.top.dotsIn.play(0);
    //   this.$refs.bottom.dotsIn.play(0);
    //   this.animate = false;
    // }
  }
};
</script>

<style lang="scss"></style>