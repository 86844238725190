<template>
  <section v-if="show">
    <slot v-bind:confirm="confirm" v-bind:decline="decline"></slot>
  </section>
</template>

<script>
import TweenMax from "gsap";

export default {
  name: "age-confirmation",
  data() {
    return {
      show: true,
    };
  },
  methods: {
    confirm() {
      console.log("CONFIRMED AGE!");
      this.$root.ageConfirmed = true;
      document.getElementsByTagName("body")[0].removeAttribute("style");
      this.$root.setCookie("age-confirmed", true, 365);
      TweenMax.staggerTo(
        ".age .logo, .age .hero, .age .consent, .age .nope",
        0.5,
        {
          opacity: 0,
        },
        0.25
      );
      TweenMax.to(".age-confirmation", 0.5, {
        opacity: 0,
        delay: 0.4,
        onComplete: () => {
          this.show = false;
          this.$root.initMcModal();
        },
      });
    },
    decline() {
      console.log("DECLINED AGE!");
    },
  },
  mounted() {
    document.getElementsByTagName("body")[0].style.overflow = "hidden";
    TweenMax.from(".age .logo", 0.5, { y: 10, opacity: 0 });
    TweenMax.from(".age .hero", 0.5, { delay: 0.2, opacity: 0 });
    TweenMax.from(".age .consent", 0.5, { delay: 0.4, y: 10, opacity: 0 });
    TweenMax.from(".age .yup", 0.5, { delay: 0.6, y: 10, opacity: 0 });
    TweenMax.from(".age .nope", 0.5, { delay: 0.8, y: 10, opacity: 0 });
  },
};
</script>

<style lang="scss" scoped>
.z-999 {
  z-index: 990;
}

.h-age {
  height: 20rem;
}

@media (min-width: 768px) {
  .h-age {
    height: 25rem;
  }
}

@media (max-height: 768px) {
  .bottom-section {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    .tear-container {
      display: block;
    }
  }
}
@media screen and (max-height: 768px) and (min-width: 768px) {
  .logo {
    width: 30vh;
  }
}
// .st0{stroke:#000;stroke-width:.4;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10}
</style>