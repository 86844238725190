<template>
  <div class="opacity-0 transition-opacity dot-line left-0 absolute top h-2 w-full" :class="pos"></div>
</template>

<script>
import TweenMax from "gsap";

export default {
  name: "dot-line",
  props: ["pos"],
  data() {
    return {
      dotsIn: null,
      dotsOut: null
    };
  },
  methods: {
    dots() {
      const size = 0.2;
      const space = 0.2;

      const rem = getComputedStyle(document.documentElement).fontSize;
      const width = this.$el.offsetWidth / rem.substr(0, 2);
      let xPos = 0;
      while (xPos < width) {
        const dot = document.createElement("div");
        dot.className = "dot";
        dot.style.height = size + "rem";
        dot.style.width = size + "rem";
        dot.style.left = xPos + "rem";
        this.$el.appendChild(dot);
        xPos += size + space;
      }
    }
  },
  mounted() {
    this.dots();

    // this.dotsIn = new TimelineMax({ paused: true });
    // this.dotsOut = new TimelineMax({ paused: true });

    // const dots = this.$el.querySelectorAll(".dot");
    // this.dotsIn.staggerFromTo(
    //   dots,
    //   0.5,
    //   { scale: 0, opacity: 0 },
    //   { scale: 1, opacity: 1 },
    //   0.02
    // );
    // this.dotsOut.staggerTo(dots, 0.5, { scale: 0, opacity: 0 }, 0.02);
  }
};
</script>

<style lang="scss">
.dot-line {
  transition-duration: 200ms;
  .dot {
    background: black;
    position: absolute;
    border-radius: 100%;
    @media (min-width: 768px) {
      background: white;
    }
  }
  .bottom {
    bottom: -0.2rem;
  }
}
</style>